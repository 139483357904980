<template>
  <div class="d-inline-flex justify-content-between">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->
      <div class="nav navbar-nav d-xl-none">
        <div class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </div>
      </div>
    </div>
    <div>
      <b-link class="navbar-brand d-xl-none" to="/">
        <b-img src="@/assets/images/logo/logo.png" width="50px" />
        <div class="title">GINO PANINO</div>
      </b-link>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.title {
  display: inline-flex;
  padding-left: 1rem;
  font-family: "amable-sans", sans-serif;
  margin-bottom: 0;
  margin-top: 12px;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 30px;
  color: #ffb31b;
}
</style>
